// src/pages/Tropas.js
import React, { useEffect, useState } from 'react';
import api from '../services/api';

function Tropas() {
    const [jogadores, setJogadores] = useState([]);
    const [selectedJogador, setSelectedJogador] = useState(null);
    const [aldeias, setAldeias] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        carregarJogadores();
    }, []);

    const carregarJogadores = async () => {
        setLoading(true);
        try {
            const response = await api.get('/dados', {
                params: {
                    // Filtros se necessário
                },
            });
            const dados = response.data;

            // Processar os jogadores únicos com aldeias mais recentes
            const jogadoresMap = new Map();
            dados.forEach((item) => {
                if (!jogadoresMap.has(item.nome)) {
                    jogadoresMap.set(item.nome, {
                        nome: item.nome,
                        aldeiasMap: new Map()
                    });
                }
                if (item.aldeia_id) {
                    const jogador = jogadoresMap.get(item.nome);
                    // Sempre atualizar para o registro mais recente
                    jogador.aldeiasMap.set(item.aldeia_id, {
                        id: item.aldeia_id,
                        nome: item.aldeia_nome,
                        tropas: JSON.parse(item.aldeia_tropas),
                        total_tropas: item.aldeia_total_tropas
                    });
                }
            });

            // Converter o Map para uma lista com aldeias únicas
            const jogadoresList = Array.from(jogadoresMap.values()).map(jogador => ({
                nome: jogador.nome,
                aldeias: Array.from(jogador.aldeiasMap.values())
            }));

            setJogadores(jogadoresList);
        } catch (error) {
            console.error('Erro ao carregar jogadores:', error);
            alert('Erro ao carregar jogadores.');
        }
        setLoading(false);
    };

    const handleJogadorClick = (jogador) => {
        setSelectedJogador(jogador);
        setAldeias(jogador.aldeias);
    };

    return (
        <div className="p-4 bg-gray-900 min-h-full text-yellow-200">
            <h1 className="text-2xl mb-4 text-yellow-300">Tropas dos Jogadores</h1>
            {loading ? (
                <div className="flex justify-center items-center">
                    <div className="loader ease-linear rounded-full border-4 border-t-4 border-yellow-500 h-12 w-12"></div>
                </div>
            ) : (
                <div className="flex flex-col md:flex-row">
                    {/* Lista de Jogadores */}
                    <div className="w-full md:w-1/3 bg-gray-800 p-3 rounded-lg mr-4 mb-4 md:mb-0">
                        <h2 className="text-xl mb-2 text-yellow-300">Jogadores</h2>
                        <ul>
                            {jogadores.map((jogador, index) => (
                                <li
                                    key={index}
                                    onClick={() => handleJogadorClick(jogador)}
                                    className={`cursor-pointer p-2 rounded mb-1 text-sm ${
                                        selectedJogador && selectedJogador.nome === jogador.nome
                                            ? 'bg-yellow-500 text-gray-900'
                                            : 'bg-gray-700 hover:bg-gray-600'
                                    } transition duration-200`}
                                >
                                    {jogador.nome}
                                </li>
                            ))}
                        </ul>
                    </div>

                    {/* Detalhes das Aldeias */}
                    <div className="w-full md:w-2/3 bg-gray-800 p-3 rounded-lg">
                        {selectedJogador ? (
                            <div>
                                <h2 className="text-xl mb-2 text-yellow-300">Aldeias de {selectedJogador.nome}</h2>
                                {aldeias.length === 0 ? (
                                    <p className="text-gray-400">Nenhuma aldeia encontrada para este jogador.</p>
                                ) : (
                                    <div className="overflow-x-auto">
                                        <table className="min-w-full bg-gray-700 text-yellow-200">
                                            <thead>
                                                <tr>
                                                    <th className="py-1 px-2 border-b border-gray-600 text-left text-sm">Aldeia</th>
                                                    <th className="py-1 px-2 border-b border-gray-600 text-center text-sm">Lanceiro</th>
                                                    <th className="py-1 px-2 border-b border-gray-600 text-center text-sm">Espadachim</th>
                                                    <th className="py-1 px-2 border-b border-gray-600 text-center text-sm">Bárbaro</th>
                                                    <th className="py-1 px-2 border-b border-gray-600 text-center text-sm">Explorador</th>
                                                    <th className="py-1 px-2 border-b border-gray-600 text-center text-sm">Cavalaria Leve</th>
                                                    <th className="py-1 px-2 border-b border-gray-600 text-center text-sm">Cavalaria Pesada</th>
                                                    <th className="py-1 px-2 border-b border-gray-600 text-center text-sm">Aríete</th>
                                                    <th className="py-1 px-2 border-b border-gray-600 text-center text-sm">Catapulta</th>
                                                    <th className="py-1 px-2 border-b border-gray-600 text-center text-sm">Paladino</th>
                                                    <th className="py-1 px-2 border-b border-gray-600 text-center text-sm">Nobre</th>
                                                    <th className="py-1 px-2 border-b border-gray-600 text-center text-sm">Milícia</th>
                                                    <th className="py-1 px-2 border-b border-gray-600 text-center text-sm">Total</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {aldeias.map((aldeia, index) => (
                                                    <tr key={aldeia.id || index} className="hover:bg-gray-600">
                                                        <td className="py-1 px-2 border-b border-gray-600">{aldeia.nome}</td>
                                                        {aldeia.tropas.slice(1).map((tropa, idx) => (
                                                            <td key={idx} className="py-1 px-2 border-b border-gray-600 text-center">
                                                                {tropa}
                                                            </td>
                                                        ))}
                                                        <td className="py-1 px-2 border-b border-gray-600 text-center">{aldeia.total_tropas.toLocaleString('pt-BR')}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <p className="text-gray-400">Selecione um jogador para ver suas aldeias e tropas.</p>
                        )}
                    </div>
                </div>
            )}
        </div>
    );

}

export default Tropas;
