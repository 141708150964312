// src/App.js
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Login from './pages/Login';
import Ranking from './pages/Ranking';
import Progresso from './pages/Progresso';
import Tropas from './pages/Tropas'; // Importar o novo componente
import Filtros from './pages/Filtros';
import AdminPanel from './pages/AdminPanel';
import Layout from './components/Layout';
import { setAuthToken } from './services/api';

function App() {
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setAuthToken(token);
        }
    }, []);

    // Função para verificar autenticação
    const PrivateRoute = ({ children }) => {
        const token = localStorage.getItem('token');
        return token ? children : <Navigate to="/" />;
    };

    return (
        <Router>
            <Routes>
                <Route path="/" element={<Login />} />
                <Route
                    path="/dashboard/*"
                    element={
                        <PrivateRoute>
                            <Layout />
                        </PrivateRoute>
                    }
                >
                    <Route path="ranking" element={<Ranking />} />
                    <Route path="progresso" element={<Progresso />} />
                    <Route path="tropas" element={<Tropas />} /> {/* Adicionar a nova rota */}
                    <Route path="filtros" element={<Filtros />} />
                    {/* Adicione mais rotas aqui para outras abas */}
                </Route>
                <Route
                    path="/admin"
                    element={
                        <PrivateRoute>
                            <AdminPanel />
                        </PrivateRoute>
                    }
                />
                {/* Redirecionar rotas desconhecidas para a aba Ranking */}
                <Route path="*" element={<Navigate to="/dashboard/ranking" />} />
            </Routes>
        </Router>
    );
}

export default App;
