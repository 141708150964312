// src/pages/Filtros.js
import React, { useEffect, useState } from 'react';
import api from '../services/api';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

function Filtros() {
    const tropasDisponiveis = [
        'lanceiro',
        'espadachim',
        'barbaro',
        'explorador',
        'cavalaria_leve',
        'cavalaria_pesada',
        'ariete',
        'catapulta',
        'paladino',
        'nobre',
        'milicia'
    ];

    const troopIndices = {
        'lanceiro': 1,
        'espadachim': 2,
        'barbaro': 3,
        'explorador': 4,
        'cavalaria_leve': 5,
        'cavalaria_pesada': 6,
        'ariete': 7,
        'catapulta': 8,
        'paladino': 9,
        'nobre': 10,
        'milicia': 11
    };

    const [filtros, setFiltros] = useState([]);
    const [nomeFiltro, setNomeFiltro] = useState('');
    const [criterios, setCriterios] = useState([
        { tropa: 'barbaro', quantidade: 0 },
        { tropa: 'cavalaria_leve', quantidade: 0 },
    ]);
    const [resultado, setResultado] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedTropas, setSelectedTropas] = useState(tropasDisponiveis); // Todas selecionadas por padrão

    useEffect(() => {
        listarFiltros();
        setSelectedTropas(tropasDisponiveis); // Garantir que todas estão selecionadas ao montar
    }, []);

    const listarFiltros = async () => {
        try {
            const response = await api.get('/dados/filtros');
            setFiltros(response.data);
        } catch (error) {
            console.error('Erro ao listar filtros:', error);
            alert('Erro ao listar filtros.');
        }
    };

    const handleCriterioChange = (index, field, value) => {
        const novosCriterios = [...criterios];
        novosCriterios[index][field] = value;
        setCriterios(novosCriterios);
    };

    const adicionarCriterio = () => {
        setCriterios([...criterios, { tropa: '', quantidade: 0 }]);
    };

    const removerCriterio = (index) => {
        const novosCriterios = [...criterios];
        novosCriterios.splice(index, 1);
        setCriterios(novosCriterios);
    };

    const criarFiltro = async (e) => {
        e.preventDefault();
        try {
            const filtro = {
                nome: nomeFiltro,
                criterio: criterios.reduce((acc, curr) => {
                    if (curr.tropa && curr.quantidade > 0) {
                        acc[curr.tropa] = parseInt(curr.quantidade);
                    }
                    return acc;
                }, {})
            };

            console.log('Enviando filtro:', filtro); // Log para debugging

            await api.post('/dados/filtros', filtro);
            alert('Filtro criado com sucesso!');
            setNomeFiltro('');
            setCriterios([{ tropa: 'barbaro', quantidade: 0 }, { tropa: 'cavalaria_leve', quantidade: 0 }]);
            listarFiltros();
        } catch (error) {
            console.error('Erro ao criar filtro:', error);
            alert('Erro ao criar filtro.');
        }
    };

    const aplicarFiltro = async (filtro_id) => {
        setLoading(true);
        try {
            const response = await api.get('/dados/filtros/aplicar', {
                params: {
                    filtro_id
                }
            });
            setResultado(response.data);
        } catch (error) {
            console.error('Erro ao aplicar filtro:', error);
            alert('Erro ao aplicar filtro.');
        }
        setLoading(false);
    };

    // Função para calcular o total de aldeias
    const calcularTotalAldeias = () => {
        return resultado.reduce((total, jogador) => total + jogador.aldeias.length, 0);
    };

    // Handler para mudança nos checkboxes
    const handleCheckboxChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
            setSelectedTropas([...selectedTropas, value]);
        } else {
            setSelectedTropas(selectedTropas.filter(tropa => tropa !== value));
        }
    };

    // Função para exportar os resultados em PDF
    const exportarPDF = () => {
        const doc = new jsPDF();

        // Definir cor de fundo escura
        doc.setFillColor(31, 41, 55); // Cor similar a bg-gray-800
        doc.rect(0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight(), 'F');

        // Adicionar título
        doc.setTextColor(255, 215, 0); // Amarelo dourado
        doc.setFontSize(18);
        doc.text('Relatório de Aldeias por Filtro', 14, 22);

        // Adicionar resumo
        doc.setFontSize(12);
        doc.setTextColor(255, 255, 255); // Branco
        doc.text(`Total de Aldeias Correspondentes: ${calcularTotalAldeias()}`, 14, 30);

        // Inicializar a posição Y
        let currentY = 35;

        resultado.forEach(jogador => {
            // Verificar se há espaço suficiente na página, caso contrário, adicionar nova página
            if (currentY + 40 > doc.internal.pageSize.height - 20) { // Ajuste conforme necessário
                doc.addPage();
                // Redefinir fundo na nova página
                doc.setFillColor(31, 41, 55);
                doc.rect(0, 0, doc.internal.pageSize.getWidth(), doc.internal.pageSize.getHeight(), 'F');
                currentY = 20;
            }

            // Adicionar nome do jogador
            doc.setFontSize(14);
            doc.setTextColor(255, 215, 0); // Amarelo dourado
            doc.text(`Jogador: ${jogador.nome}`, 14, currentY + 10);
            currentY += 10;

            // Definir colunas com base nas tropas selecionadas
            const colunas = ['Aldeia'];
            if (selectedTropas.length > 0) {
                colunas.push(...selectedTropas.map(tropa => tropa.charAt(0).toUpperCase() + tropa.slice(1).replace('_', ' ')));
            }
            // Não adicionamos mais a coluna 'Total'

            // Definir corpo da tabela
            const corpo = jogador.aldeias.map(aldeia => {
                const row = [aldeia.nome];
                if (selectedTropas.length > 0) {
                    selectedTropas.forEach(tropa => {
                        // Encontrar o índice correspondente à tropa
                        const index = troopIndices[tropa];
                        row.push(aldeia.tropas[index] || 0);
                    });
                }
                // Não adicionamos mais o total_tropas
                return row;
            });

            // Adicionar tabela ao PDF
            doc.autoTable({
                startY: currentY + 5,
                head: [colunas],
                body: corpo,
                theme: 'grid',
                headStyles: { fillColor: [75, 85, 99], textColor: [255, 215, 0] }, // bg-gray-700, text-yellow-200
                bodyStyles: { fillColor: [31, 41, 55], textColor: [255, 255, 255] }, // bg-gray-800, text-white
                styles: { fontSize: 10, halign: 'center' },
                margin: { left: 14, right: 14 },
                tableLineColor: [255, 215, 0], // Bordas amarelas
                tableLineWidth: 0.1,
                didDrawPage: (data) => {
                    currentY = data.cursor.y;
                }
            });

            // Atualizar posição Y para próximo jogador
            currentY = doc.autoTable.previous.finalY + 10;
        });

        // Salvar PDF
        doc.save('Relatorio_Aldeias.pdf');

        // Feedback ao usuário
        alert('PDF exportado com sucesso!');
    };

    return (
        <div className="p-4 bg-gray-900 min-h-full text-yellow-200">
            <h1 className="text-2xl mb-4 text-yellow-300">Filtros de Aldeias</h1>

            {/* Formulário para criar um novo filtro */}
            <div className="bg-gray-800 p-4 rounded-lg mb-6">
                <h2 className="text-xl mb-2 text-yellow-300">Criar Novo Filtro</h2>
                <form onSubmit={criarFiltro}>
                    <div className="mb-4">
                        <label className="block mb-1">Nome do Filtro:</label>
                        <input
                            type="text"
                            value={nomeFiltro}
                            onChange={(e) => setNomeFiltro(e.target.value)}
                            className="w-full p-2 rounded bg-gray-700 text-yellow-200"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1">Critérios de Tropas:</label>
                        {criterios.map((criterio, index) => (
                            <div key={index} className="flex items-center mb-2">
                                <select
                                    value={criterio.tropa}
                                    onChange={(e) => handleCriterioChange(index, 'tropa', e.target.value)}
                                    className="w-1/3 p-2 rounded bg-gray-700 text-yellow-200 mr-2"
                                    required
                                >
                                    <option value="">Selecionar Tropa</option>
                                    {tropasDisponiveis.map((tropa) => (
                                        <option key={tropa} value={tropa}>
                                            {tropa.charAt(0).toUpperCase() + tropa.slice(1).replace('_', ' ')}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    type="number"
                                    placeholder="Quantidade"
                                    value={criterio.quantidade}
                                    onChange={(e) => handleCriterioChange(index, 'quantidade', e.target.value)}
                                    className="w-1/3 p-2 rounded bg-gray-700 text-yellow-200 mr-2"
                                    min="0"
                                    required
                                />
                                {criterios.length > 2 && (
                                    <button type="button" onClick={() => removerCriterio(index)} className="text-red-500">
                                        Remover
                                    </button>
                                )}
                            </div>
                        ))}
                        <button type="button" onClick={adicionarCriterio} className="text-blue-500">
                            Adicionar Critério
                        </button>
                    </div>
                    <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded">
                        Criar Filtro
                    </button>
                </form>
            </div>

            {/* Lista de Filtros Existentes */}
            <div className="bg-gray-800 p-4 rounded-lg mb-6">
                <h2 className="text-xl mb-2 text-yellow-300">Filtros Disponíveis</h2>
                {filtros.length === 0 ? (
                    <p className="text-gray-400">Nenhum filtro criado.</p>
                ) : (
                    <ul>
                        {filtros.map((filtro) => (
                            <li key={filtro.id} className="mb-2">
                                <div className="flex justify-between items-center bg-gray-700 p-2 rounded">
                                    <span>{filtro.nome}</span>
                                    <button
                                        onClick={() => aplicarFiltro(filtro.id)}
                                        className="bg-green-500 text-white px-3 py-1 rounded"
                                    >
                                        Aplicar
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
            </div>

            {/* Resultados da Aplicação do Filtro */}
            <div className="bg-gray-800 p-4 rounded-lg">
                <h2 className="text-xl mb-2 text-yellow-300">Resultados</h2>
                {loading ? (
                    <div className="flex justify-center items-center">
                        <div className="loader ease-linear rounded-full border-4 border-t-4 border-yellow-500 h-12 w-12"></div>
                    </div>
                ) : (
                    <>
                        {resultado.length === 0 ? (
                            <p className="text-gray-400">Nenhum resultado para o filtro aplicado.</p>
                        ) : (
                            <>
                                {/* Tabela Resumo */}
                                <div className="mb-4">
                                    <h3 className="text-lg mb-2 text-yellow-300">Resumo</h3>
                                    <table className="min-w-full bg-gray-700 text-yellow-200">
                                        <thead>
                                            <tr>
                                                <th className="py-1 px-2 border-b border-gray-600 text-left text-sm">Total de Aldeias Correspondentes</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className="py-1 px-2 border-b border-gray-600">{calcularTotalAldeias()}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                {/* Seção de Seleção de Tropas para Exportação */}
                                <div className="mb-4">
                                    <h3 className="text-lg mb-2 text-yellow-300">Selecione as Tropas para Exportar</h3>
                                    <div className="flex flex-wrap">
                                        {tropasDisponiveis.map((tropa) => (
                                            <label key={tropa} className="mr-4 mb-2 flex items-center">
                                                <input
                                                    type="checkbox"
                                                    value={tropa}
                                                    checked={selectedTropas.includes(tropa)}
                                                    onChange={handleCheckboxChange}
                                                    className="mr-2"
                                                />
                                                {tropa.charAt(0).toUpperCase() + tropa.slice(1).replace('_', ' ')}
                                            </label>
                                        ))}
                                    </div>
                                </div>

                                {/* Botão para Exportar PDF */}
                                <div className="mb-4">
                                    <button
                                        onClick={exportarPDF}
                                        className={`bg-purple-500 text-white px-4 py-2 rounded ${resultado.length === 0 ? 'opacity-50 cursor-not-allowed' : ''
                                            }`}
                                        disabled={resultado.length === 0}
                                    >
                                        Exportar para PDF
                                    </button>
                                </div>

                                {/* Tabela de Resultados */}
                                <div className="overflow-x-auto">
                                    {resultado.map((jogador, index) => (
                                        <div key={index} className="mb-4">
                                            <h3 className="text-lg mb-2 text-yellow-300">Jogador: {jogador.nome}</h3>
                                            <table className="min-w-full bg-gray-700 text-yellow-200 mb-2">
                                                <thead>
                                                    <tr>
                                                        <th className="py-1 px-2 border-b border-gray-600 text-left text-sm">Aldeia</th>
                                                        {selectedTropas.length > 0 && selectedTropas.map((tropa, idx) => (
                                                            <th key={idx} className="py-1 px-2 border-b border-gray-600 text-center text-sm">
                                                                {tropa.charAt(0).toUpperCase() + tropa.slice(1).replace('_', ' ')}
                                                            </th>
                                                        ))}
                                                        {/* Removemos a coluna 'Total' */}
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {jogador.aldeias.map((aldeia, idx) => (
                                                        <tr key={aldeia.id || idx} className="hover:bg-gray-600">
                                                            <td className="py-1 px-2 border-b border-gray-600">{aldeia.nome}</td>
                                                            {selectedTropas.length > 0 && selectedTropas.map((tropa, idx2) => (
                                                                <td key={idx2} className="py-1 px-2 border-b border-gray-600 text-center">
                                                                    {aldeia.tropas[troopIndices[tropa]] || 0}
                                                                </td>
                                                            ))}
                                                            {/* Removemos a célula 'Total' */}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    ))}
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
            </div>
        );
    }

    export default Filtros;
